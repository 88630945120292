import { Button, Tag, Typography } from "antd"
import { ColumnsType } from "antd/lib/table"
import React from "react"
import { ActionUserRes } from "../actions/ActionUser"
import { GetDraftRes, GetUserRes } from "../api"
import JJ_TableItemAction from "../components/JJ_TableItemAction"
import JJ_TableItemCopyableId from "../components/JJ_TableItemCopyableId"
import JJ_TableItemMoment from "../components/JJ_TableItemMoment"
import { getCreatedAtAndUpdataAtColumn } from "./Pablic"
import { Link } from 'react-router-dom';


export const getApproveStatusInfo = (status: GetDraftRes['approveStatus']) => {
  switch (status) {
    case 'BEGIN': {
      const name = '準備中'
      return {
        value: status,
        name,
        tag: (
          <Tag color='blue'>
            {name}
          </Tag>
        )
      }
    }
    case 'CANCEL': {
      const name = '取消審批'
      return {
        value: status,
        name,
        tag: (
          <Tag color='warning'>
            {name}
          </Tag>
        )
      }
    }
    case 'COMPLETED': {
      const name = '完成審批'
      return {
        value: status,
        name,
        tag: (
          <Tag color='success'>
            {name}
          </Tag>
        )
      }
    }
    case 'WAITING': {
      const name = '審批延遲'
      return {
        value: status,
        name,
        tag: (
          <Tag color='pink'>
            {name}
          </Tag>
        )
      }
    }
  }

}

export type DraftColumnData = GetDraftRes & {
  createUser?: GetUserRes
  approveUser?: GetUserRes
}

type DraftColumnParamOnActionKey = 'edit' | 'delete' | 'detail' | 'completed' | 'cancel' | 'begin'
type DraftColumnParams = {
  onAction?(key: DraftColumnParamOnActionKey, record: DraftColumnData): void
  user: ActionUserRes

}

export const getDraftColumns = (params?: DraftColumnParams): ColumnsType<DraftColumnData> => {

  return [
    {
      key: 'action',
      width: 50,
      render: (value: any, record) => {
        const menusData: { key: DraftColumnParamOnActionKey, name: string, disabled?: boolean, link?: string }[] = [
          { key: 'edit', name: '編輯' },
          { key: 'detail', name: '進入詳情', link: `/draft/main/${record.id}/draft-record/list` },
          { key: 'delete', name: '删除' },
          { key: 'completed', name: '審批', disabled: !!(record.approveStatus !== 'BEGIN') },
          { key: 'cancel', name: '取消審核', disabled: !!(record.approveStatus !== 'BEGIN') },
          { key: 'begin', name: '恢復準備中', disabled: !(record.approveStatus === 'WAITING' || record.approveStatus === 'CANCEL') },
        ]
        return (
          <JJ_TableItemAction
            menus={
              menusData.map(item => ({
                type: 'item',
                disabled: item.disabled,
                key: item.key,
                name: item.name,
                link: item.link,
                onAction: () => {
                  params && params.onAction && params.onAction(item.key, record)
                }
              }))
            }
          />
        )

      },
    },



    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      width: 50,
      render: (value) => (
        <JJ_TableItemCopyableId
          text={value}
        />
      )
    },

    {
      title: '名稱',
      dataIndex: 'name',
      key: 'name',
      width: 120,
      render: (value, record) => (

        <Link to={`/draft/main/${record.id}/draft-record/list`}>
          <span> {value}</span>
        </Link>



      )
    },

    {
      title: '審批狀態',
      dataIndex: 'approveStatus',
      key: 'approveStatus',
      width: 100,
      render: (value, record) => getApproveStatusInfo(record.approveStatus).tag
    },

    {
      title: '創建用戶',
      dataIndex: 'createUser',
      key: 'createUser',
      width: 120,
      render: (value, record) => record.createUser?.nickname
    },
    {
      title: '生效時間',
      dataIndex: 'approvedAt',
      key: 'approvedAt',
      width: 120,
      render: (value) => (
        <JJ_TableItemMoment
          date={value}
        />
      )
    },

    {
      title: '審批用戶',
      dataIndex: 'approveUser',
      key: 'approveUser',
      width: 120,
      render: (value, record) => record.approveUser?.nickname
    },


    {
      title: '備註',
      dataIndex: 'remark',
      key: 'remark',
      width: 150,
      render: (value, record) => {
        return (
          <Typography.Paragraph
            ellipsis={{
              rows: 1,
              expandable: true,
            }}
            style={{
              whiteSpace: 'pre-wrap',
            }}
          >
            {value}
          </Typography.Paragraph>
        )
      }
    },
    ...getCreatedAtAndUpdataAtColumn(),
  ]
}
